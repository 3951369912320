<template>
  <main>
    <div class="all" @click="$router.push('/dashboard/bills/bills-categories')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        focusable="false"
        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path d="M8 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="#db353a" />
          <path d="M8 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="#db353a" />
          <path d="M6 20a2 2 0 1 0 0-4a2 2 0 0 0 0 4z" fill="#db353a" />
          <path d="M14 6a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="#db353a" />
          <path d="M12 14a2 2 0 1 0 0-4a2 2 0 0 0 0 4z" fill="#db353a" />
          <path d="M14 18a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="#db353a" />
          <path d="M18 8a2 2 0 1 0 0-4a2 2 0 0 0 0 4z" fill="#db353a" />
          <path d="M20 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="#db353a" />
          <path d="M18 20a2 2 0 1 0 0-4a2 2 0 0 0 0 4z" fill="#db353a" />
        </g>
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
      <p>All</p>
    </div>

    <section
      class="bills"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="bills__wrapper">
        <div class="bills__group">
          <div class="bills__heading">
            <h2>Electricity</h2>
            <p @click="saveCategoryDetails('utility')">More</p>
          </div>
          <div class="bills__type">
            <div class="bill" @click="getOtherCategories('eko')">
              <div class="bill__image">
                <img src="../../assets/images/eko-edc.jpg" alt="Eko EDC" />
              </div>
              <p>Eko EDC</p>
            </div>
            <div class="bill" @click="getOtherCategories('ikeja')">
              <div class="bill__image">
                <img
                  src="../../assets/images/ikeja-electric.png"
                  alt="Ikeja Electric"
                />
              </div>
              <p>Ikeja Electric</p>
            </div>
          </div>
        </div>

        <div class="bills__group">
          <div class="bills__heading">
            <h2>Cable TV</h2>
            <p @click="saveCategoryDetails('cable')">More</p>
          </div>
          <div class="bills__type">
            <div class="bill" @click="payBiller('dstv')">
              <div class="bill__image">
                <img src="../../assets/images/dstv-logo.png" alt="DSTV Logo" />
              </div>
              <p>DSTV</p>
            </div>
            <div class="bill" @click="payBiller('gotv')">
              <div class="bill__image">
                <img src="../../assets/images/gotv-logo.png" alt="GOTV Logo" />
              </div>
              <p>GoTV</p>
            </div>
          </div>
        </div>

        <div class="bills__group">
          <div class="bills__heading">
            <h2>Internet Services</h2>
            <p @click="saveCategoryDetails('internet-services')">More</p>
          </div>
          <div class="bills__type">
            <div class="bill" @click="payBiller('smile')">
              <div class="bill__image">
                <img
                  src="../../assets/images/smile-logo.gif"
                  alt="Smile Logo"
                />
              </div>
              <p>Smile</p>
            </div>
            <div class="bill" @click="payBiller('swift')">
              <div class="bill__image">
                <img
                  src="../../assets/images/swift-logo.gif"
                  alt="Swift Logo"
                />
              </div>
              <p>Swift</p>
            </div>
            <div class="bill" @click="payBiller('spectranet')">
              <div class="bill__image">
                <img
                  src="../../assets/images/spectranet-logo.gif"
                  alt="Spectranet Logo"
                />
              </div>
              <p>Spectranet</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import api from "@/api/api.js";
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    saveCategoryDetails(value) {
      let details;
      switch (value) {
        case "utility":
          details = {
            name: "Utility Bills",
            categoryid: "1",
          };
          break;
        case "cable":
          details = {
            name: "Cable TV Bills",
            categoryid: "2",
          };
          break;
        case "internet-services":
          details = {
            name: "Internet Services",
            categoryid: "5",
          };
          break;

        default:
          break;
      }
      this.$store.dispatch("setCategoryDetails", details);
      this.$router.push("/dashboard/bills/category");
    },

    getOtherCategories(value) {
      let details;
      switch (value) {
        case "eko":
          details = {
            name: "Eko Electricity",
            categoryid: "1",
          };
          break;
        case "ikeja":
          details = {
            name: "Ikeja Electric",
            categoryid: "1",
          };
          break;

        default:
          break;
      }
      this.$store.dispatch("setOtherCategoryDetails", details);
      this.$router.push("/dashboard/bills/sub-categories");
    },
    payBiller(value) {
      let details = {
        billername: "",
        categoryid: "",
      };

      let payload = {
        billerId: "",
      };
      switch (value) {
        case "dstv":
          details.billername = "DSTV Subscription";
          details.categoryid = "2";
          payload.billerId = "104";
          break;
        case "gotv":
          details.billername = "GoTV Subscription";
          details.categoryid = "2";
          payload.billerId = "459";
          break;
        case "smile":
          details.billername = "Smile Bundle";
          details.categoryid = "5";
          payload.billerId = "857";
          break;
        case "swift":
          details.billername = "Swift 4G Subscription";
          details.categoryid = "5";
          payload.billerId = "202";
          break;
        case "spectranet":
          details.billername = "Spectranet Limited";
          details.categoryid = "5";
          payload.billerId = "991";
          break;

        default:
          break;
      }

      this.loading = true;

      api
        .getBillerPayItems(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let subCategories = response.Data;

            if (subCategories <= 0) {
              this.$message({
                showClose: true,
                message: `No records found.`,
                type: "error",
                duration: 5000,
              });
              return;
            }

            this.$store.dispatch("setCategoryTwoDetails", details);
            this.$store.dispatch("setSubCategoryDetails", subCategories);
            this.$router.push("/dashboard/bills/pay-biller");
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
  mounted() {
    this.loading = true;

    api
      .getBillerCategories()
      .then((response) => {
        this.loading = false;
        let responseStatus = response.Status;
        let responseMessage = response.Message;

        if (responseStatus) {
          let categoriesList = response.Data;

          if (categoriesList <= 0) {
            this.$message({
              showClose: true,
              message: `No records found.`,
              type: "error",
              duration: 5000,
            });
          }

          this.$store.dispatch("setAllCategoriesDetails", categoriesList);
        } else {
          this.$message({
            showClose: true,
            message: `${responseMessage.charAt(0).toUpperCase() +
              responseMessage.slice(1)}`,
            type: "error",
            duration: 10000,
          });
        }
      })
      .catch((error) => {
        this.loading = false;
        this.$message({
          showClose: true,
          message: `${error}`,
          type: "error",
          duration: 10000,
        });
      });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_billsPayment.scss";
</style>
